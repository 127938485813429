import React from "react";
import Navbar from "../../ComponentUser/Narbar";
import Profile from "../../ComponentUser/Profile/Profile";

export default function Profiles() {
  return (
    <div>
      <Navbar />
      <Profile />
    </div>
  );
}
