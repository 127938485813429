import MiniCourses from "../../ComponentUser/MiniCourses/MiniCourses";
import Navbar from "../../ComponentUser/Narbar";

export default function Minicourses() {
  return (
    <div>
      <Navbar />
      <MiniCourses />
    </div>
  );
}
